import "moment/locale/cs"
import "react-day-picker/lib/style.css"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import AutoComplete from "react-google-autocomplete";
import cx from "classnames"
import {
  ErrorMessage,
  FastField,
  Field,
  Form,
  useFormikContext,
  withFormik,
} from "formik"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import moment from "moment"
import React, { useEffect, useContext, useRef, useState } from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import { DateUtils } from "react-day-picker";
import { Trans, useTranslation } from "react-i18next"
import { AffiliateContext } from "../../contexts/AffiliateContext"
import Select from "react-select"
import MaskedInput from "react-text-mask"
import * as Yup from "yup"
import { useModal } from "../modal2"
import { formatPhoneNumber, getCookie, getReferrer, getUTM } from "../../helpers"
import { useLangContext } from "../../utils/lang"
import { Button } from "../button"
import { Button2 } from "../button2"
import stylesRadio from "../calculator2/calc2.module.css"
import form2Styles from "../forms/form2.module.css"
import { Price2 } from "../order-price2"
import { Summary2 } from "../order-summary2"
import { CustomCheckbox } from "./custom-checkbox"
import styles from "./form.module.css"
import {
  AddressErrorMessage,
  EmailErrorMessage,
  EmailErrorRequiredMessage,
  NameErrorRequiredMessage,
  PhoneErrorMessage,
  Terms2ErrorRequiredMessage,
  TermsErrorRequiredMessage,
} from "./forms-error-messages"
import option3Img from "./icons/icon-2months.svg"
import option1Img from "./icons/icon-demo.svg"
import option2Img from "./icons/icon-month.svg"
import nabiratIcon from "./icons/nabirat-icon.svg"
import udrzovatIcon from "./icons/udrzovat-icon.svg"
import zhubnostIcon from "./icons/zhubnout-icon.svg"
import mainFormStyles from "./main-form2.module.css"
import orderFormStyles from "./order-form2.module.css"
import { MaskedPhoneInput } from "./phone"
import { DeliveryAddressInput } from "./delivery-address-input"
import { PromoInput } from "./promo"
import { GoogleSearchAddressInput } from "./google-search-address-input"

function getDiscountPrice(price) {
  const discount = 10
  return (price - price * (discount / 100)).toFixed()
}


// import cx from 'classnames';
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phone", "Telefonní číslo musí obsahovat 12 znaků", value =>
    rePhoneNumber.test(value)
  )
})

function dateToSystemFormat(date) {
  return moment(date)
    .utc()
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toISOString()
}

function getStartDateDay() {
  if ([2, 4, 6].indexOf(moment.utc().isoWeekday()) !== -1) {
    return 6
  } else if ([1, 3, 5].indexOf(moment.utc().isoWeekday()) !== -1) {
    return 7
  } else if ([7].indexOf(moment.utc().isoWeekday()) !== -1) {
    return 5
  }
}

function getStartDate() {
  let initial = moment.utc().add(2, "days")
  if ([2, 4, 7].indexOf(moment.utc().isoWeekday()) !== -1) {
    initial = moment
      .utc()
      .add(2, "days")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toISOString()
  } else if ([1, 3, 6].indexOf(moment.utc().isoWeekday()) !== -1) {
    initial = moment
      .utc()
      .add(3, "days")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toISOString()
  } else if ([5].indexOf(moment.utc().isoWeekday()) !== -1) {
    initial = moment
      .utc()
      .add(4, "days")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toISOString()
  }

  return initial
}

const pricePreset = {
  "5chodové menu": {
    "2 týdny": {
      1400: 30.99,
      1600: 31.99,
      1800: 32.99,
      2400: 33.99,
      3000: 34.99,
      3400: 35.99,
    },
    Měsíc: {
      1400: 29.99,
      1600: 30.99,
      1800: 31.99,
      2400: 32.99,
      3000: 33.99,
      3400: 34.99,
    },
    "Dva měsíce": {
      1400: 27.99,
      1600: 28.99,
      1800: 29.99,
      2400: 30.99,
      3000: 31.99,
      3400: 32.99,
    },
    "1 week": {
      1400: 31.99,
      1600: 32.99,
      1800: 33.99,
      2400: 34.99,
      3000: 35.99,
      3400: 36.99,
    },
  },
  "4chodové menu": {
    "2 týdny": {
      1400: 27.89,
      1600: 28.79,
      1800: 29.69,
      2400: 30.59,
      3000: 31.49,
      3400: 32.39,
    },
    Měsíc: {
      1400: 26.99,
      1600: 27.89,
      1800: 28.79,
      2400: 29.69,
      3000: 30.59,
      3400: 31.49,
    },
    "Dva měsíce": {
      1400: 25.19,
      1600: 26.09,
      1800: 26.99,
      2400: 27.89,
      3000: 28.79,
      3400: 29.69,
    },
    "1 week": {
      1400: 28.69,
      1600: 29.69,
      1800: 30.69,
      2400: 31.69,
      3000: 32.69,
      3400: 33.69,
    },
  },
  "3chodové menu": {
    "2 týdny": {
      1400: 24.79,
      1600: 25.59,
      1800: 26.39,
      2400: 27.19,
      3000: 27.99,
      3400: 28.79,
    },
    Měsíc: {
      1400: 23.99,
      1600: 24.79,
      1800: 25.59,
      2400: 26.39,
      3000: 27.19,
      3400: 27.99,
    },
    "Dva měsíce": {
      1400: 22.39,
      1600: 23.19,
      1800: 23.99,
      2400: 24.79,
      3000: 25.59,
      3400: 26.39,
    },
    "1 week": {
      1400: 25.59,
      1600: 26.39,
      1800: 27.19,
      2400: 27.99,
      3000: 28.79,
      3400: 29.59,
    },
  },
  "2chodové menu": {
    "2 týdny": {
      1400: 23.09,
      // 1600: 318,
      // 1800: 318,
      // 2400: 318,
      // 3000: 318,
      // 3400: 318,
    },
    Měsíc: {
      1400: 22.39,
      // 1600: 308,
      // 1800: 308,
      // 2400: 308,
      // 3000: 308,
      // 3400: 308,
    },
    "Dva měsíce": {
      1400: 20.99,
      // 1600: 288,
      // 1800: 288,
      // 2400: 288,
      // 3000: 288,
      // 3400: 288,
    },
    "1 week": {
      1400: 23.79,
      // 1600: 288,
      // 1800: 288,
      // 2400: 288,
      // 3000: 288,
      // 3400: 288,
    },
  },
}

function energyRange(energyPerDay) {
  if (energyPerDay <= 1400) {
    return 1400
  }
  if (energyPerDay <= 1600) {
    return 1600
  }
  if (energyPerDay <= 1800) {
    return 1800
  }
  if (energyPerDay <= 2400) {
    return 2400
  }
  if (energyPerDay <= 3000) {
    return 3000
  }
  return 3400
}

const kCalOptions = {
  Zhubnout: {
    female: {
      "5chodové menu": [
        { value: 1600, label: "1600 kCal" },
        { value: 1800, label: "1800 kCal" },
      ],
      "4chodové menu": [
        { value: 1600, label: "1600 kCal" },
        { value: 1800, label: "1800 kCal" },
      ],
      "3chodové menu": [
        { value: 1100, label: "1100 kCal" },
        { value: 1300, label: "1300 kCal" },
      ],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
    male: {
      "5chodové menu": [
        { value: 1800, label: "1800 kCal" },
        { value: 2000, label: "2000 kCal" },
      ],
      "4chodové menu": [
        { value: 1800, label: "1600 kCal" },
        { value: 2000, label: "1800 kCal" },
      ],
      "3chodové menu": [
        { value: 1600, label: "1600 kCal" },
        { value: 1800, label: "1800 kCal" },
      ],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
  },
  Udržovat: {
    female: {
      "5chodové menu": [
        { value: 1900, label: "1900 kCal" },
        { value: 2100, label: "2100 kCal" },
      ],
      "4chodové menu": [
        { value: 1900, label: "1900 kCal" },
        { value: 2100, label: "2100 kCal" },
      ],
      "3chodové menu": [
        { value: 1400, label: "1400 kCal" },
        { value: 1600, label: "1600 kCal" },
      ],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
    male: {
      "5chodové menu": [
        { value: 2100, label: "2100 kCal" },
        { value: 2300, label: "2300 kCal" },
      ],
      "4chodové menu": [
        { value: 2100, label: "2100 kCal" },
        { value: 2300, label: "2300 kCal" },
      ],
      "3chodové menu": [{ value: 1900, label: "1900 kCal" }],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
  },
  Nabírat: {
    female: {
      "5chodové menu": [
        { value: 2200, label: "2200 kCal" },
        { value: 2600, label: "2600 kCal" },
      ],
      "4chodové menu": [
        { value: 2200, label: "2200 kCal" },
        { value: 2600, label: "2600 kCal" },
      ],
      "3chodové menu": [
        { value: 0, label: "0 kCal" },
        { value: 0, label: "0 kCal" },
      ],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
    male: {
      "5chodové menu": [
        { value: 2400, label: "2400 kCal" },
        { value: 2600, label: "2600 kCal" },
      ],
      "4chodové menu": [
        { value: 2400, label: "2400 kCal" },
        { value: 2600, label: "2600 kCal" },
      ],
      "3chodové menu": [{ value: 0, label: "0 kCal" }],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
  },
  "Office pack": {
    female: {
      "5chodové menu": [
        { value: 2200, label: "2200 kCal" },
        { value: 2600, label: "2600 kCal" },
      ],
      "3chodové menu": [
        { value: 0, label: "0 kCal" },
        { value: 0, label: "0 kCal" },
      ],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
    male: {
      "5chodové menu": [
        { value: 2400, label: "2400 kCal" },
        { value: 2600, label: "2600 kCal" },
      ],
      "3chodové menu": [{ value: 0, label: "0 kCal" }],
      "2chodové menu": [{ value: 1000, label: "1000 kCal" }],
    },
  },
}

// Input feedback
const InputFeedback = ({ error }) =>
  error ? <div className={cx("input-feedback")}>{error}</div> : null

// Radio input
const RadioButton = ({
  field: { name, value, onChange },
  id,
  label,
  title,
  className,
  withImg = false,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={cx(
        stylesRadio.radio,
        stylesRadio.radioBtn,
        {
          [stylesRadio.withImg]: withImg,
          [stylesRadio.disabled]: disabled,
        },
        className
      )}
    >
      <input
        name={name}
        id={id}
        type="radio"
        value={label}
        checked={label === value}
        onChange={onChange}
        {...props}
      />
      <label htmlFor={id}>
        {withImg && (
          <img src={props.img} className={stylesRadio.radioImg} alt={label} />
        )}
        {title}
      </label>
    </div>
  )
}

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  className,
  onChange,
  children,
}) => {
  const classes = cx(
    {
      success: value || (!error && touched),
      error: !!error && touched,
    },
    className
  )

  return (
    <div className={classes} onChange={onChange}>
      {children}
      {touched && <InputFeedback error={error} />}
    </div>
  )
}

export const WrappedForm = ({ isSubmitting, values, errors, touched }) => {
  return <OrderForm2 />
}

const OrderFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  validateInputs,
}) => {
  const [plan, setPlan] = useState("Zhubnout")
  const [program, setProgram] = useState("Měsíc")
  const [menu, setMenu] = useState("5chodové menu")
  const [paymentMethod, setPaymentMethod] = useState("Zahlung mit Kreditkarte")
  const [week, setWeek] = useState("6")
  const [gender, setGender] = useState("female")
  const [deliveryTime, setDeliveryTime] = useState("17:00-19:00")
  const [kcal, setKcal] = useState(1600)
  const [price, setPrice] = useState(window.localStorage.getItem("price") || "420")
  const [delivery, setDelivery] = useState(Number(window.localStorage.getItem("price")) || 0);
  const [kcalDisabled, setKcalDisabled] = useState(false)
  const [menu2xDisabled, setMenu2xDisabled] = useState(true)
  const [menu3xDisabled, setMenu3xDisabled] = useState(true)
  const [menu4xDisabled, setMenu4xDisabled] = useState(true)
  const [menu5xDisabled, setMenu5xDisabled] = useState(true)
  const [checkTerms, setCheckTerms] = useState(false)
  const [checkTerms2, setCheckTerms2] = useState(false)
  const [step, setStep] = useState(1)
  const [isSecondStepValid, setIsSecondStepValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const { show, RenderModal } = useModal()

  const nameRef = useRef()
  const phoneRef = useRef()
  const emailRef = useRef()
  const addressRef = useRef()
  const promoRef = useRef()

  const { submitForm } = useFormikContext()

  const handleCustomSubmit = () => {
    submitForm()
  }

  const validateSecondStep = values => {
    // firstly initiate native formik check of validity
    nameRef.current.focus()
    nameRef.current.blur()
    phoneRef.current.focus()
    phoneRef.current.blur()
    emailRef.current.focus()
    emailRef.current.blur()
    addressRef.current.focus()
    addressRef.current.blur()

    // then check validity from values object
    if (values.email && values.name && values.address && values.phone) {
      setIsSecondStepValid(true)
    } else {
      setIsSecondStepValid(false)
    }
    // and return this state
    return values.email && values.name && values.address && values.phone
  }

  const handleSetDelivery = (status) => {
    console.log("run handleSetDelivery", status)
    switch(true) {
      case status === false:
        setDelivery(50);
        setFieldValue("deliveryPrice", 50);
        return 50;
      case status === true:
      case status === null:
      default:
        setDelivery(0);
        setFieldValue("deliveryPrice", 0);
        return 0;
    }
  }

  const handleChange = (name, e) => {
    // const validateSingleField = (name, value) => {
    //   const error = validateInputs({ [name]: value });
    //   setFieldError(name, error[name]); // set / clear error message
    // };

    setFieldValue(name, e.target.value)

    // validate local field only
    // validateSingleField(name, e.target.value);
  }

  const gotoNextStep = () => {
    setStep(prev => prev + 1)
  }

  const gotoPrevStep = () => {
    setStep(prev => prev - 1)
  }

  const gotoStep = step => {
    setStep(step)
  }

  const { lang } = useLangContext()
  const { t } = useTranslation()

  const options = [
    { value: 5, label: t("forms.onlineOrderFormWeekLengthOption1") },
    { value: 6, label: t("forms.onlineOrderFormWeekLengthOption2") },
  ]

  const goal = [
    {
      value: "Zhubnout",
      label: t("forms.onlineOrderFormGoalLoss"),
      disabled: false,
    },
    {
      value: "Udržovat",
      label: t("forms.onlineOrderFormGoalMaintenance"),
      disabled: false,
    },
    {
      value: "Nabírat",
      label: t("forms.onlineOrderFormGoalGain"),
      disabled: false,
    },
    {
      value: "Office pack",
      label: t("forms.mainFormGoalOption4"),
      disabled: false,
    },
  ]

  // const mealsPerDay = menu === "2chodové menu" ? 2 : menu === "3chodové menu" ? 3 : 5
  // const diet = plan === "Zhubnout" ? "loose" : plan === "Udržovat" ? "keep" : "gain"

  const onSetKcal = value => {
    setKcal(value)
    trackCustomEvent({
      category: "order",
      action: value,
      label: "HP",
    })
  }

  const getMenuSelectLabel = value => {
    switch (value) {
      case "5chodové menu":
        return t("forms.mainFormMenuOption1")
      case "4chodové menu":
        return t("forms.mainFormMenuOption4")
      case "3chodové menu":
        return t("forms.mainFormMenuOption2")
      case "2chodové menu":
        return t("forms.mainFormMenuOption3")
      default:
        return
    }
  }

  const getPaymentMethodSelectLabel = value => {
    switch (value) {
      case "card":
        return t("forms.payment.label1")
      case "stripe-card":
        return t("forms.payment.label2")
      case "stripe-klarna":
        return t("forms.payment.label3")
      case "stripe-giropay":
        return t("forms.payment.label4")
      case "stripe":
        return t("forms.payment.label5")
      case "invoice":
        return t("forms.payment.label6")
      default:
        return
    }
  }

  const onSetPlan = value => {
    setPlan(value)
    if (value === "Nabírat") {
      setMenu5xDisabled(false)
      setMenu4xDisabled(true)
      setMenu3xDisabled(true)
      setMenu2xDisabled(true)
      onSetMenu("5chodové menu")
      setFieldValue("menu", "5chodové menu")
      setKcal(Number(kCalOptions[value][gender]["5chodové menu"][0].value))
      setFieldValue(
        "kcal",
        Number(kCalOptions[value][gender]["5chodové menu"][0].value)
      )
    }

    if (value === "Zhubnout") {
      setMenu5xDisabled(false)
      setMenu4xDisabled(false)
      setMenu3xDisabled(false)
      setMenu2xDisabled(true)
      onSetMenu("5chodové menu")
      setFieldValue("menu", "5chodové menu")
      setKcal(Number(kCalOptions[value][gender]["5chodové menu"][0].value))
      setFieldValue(
        "kcal",
        Number(kCalOptions[value][gender]["5chodové menu"][0].value)
      )
    }

    if (value === "Udržovat") {
      setMenu5xDisabled(false)
      setMenu4xDisabled(false)
      setMenu3xDisabled(false)
      setMenu2xDisabled(true)
      onSetMenu("5chodové menu")
      setFieldValue("menu", "5chodové menu")
      setKcal(Number(kCalOptions[value][gender]["5chodové menu"][0].value))
      setFieldValue(
        "kcal",
        Number(kCalOptions[value][gender]["5chodové menu"][0].value)
      )
    }

    if (value === "Office pack") {
      setMenu5xDisabled(true)
      setMenu4xDisabled(true)
      setMenu3xDisabled(true)
      setMenu2xDisabled(false)
      onSetMenu("2chodové menu")
      setFieldValue("menu", "2chodové menu")
      setKcal(Number(kCalOptions[value][gender]["2chodové menu"][0].value))
      setFieldValue(
        "kcal",
        Number(kCalOptions[value][gender]["2chodové menu"][0].value)
      )
    }

    console.log(
      "WHILE SET PLAN SET KAL AS WELL:",
      value,
      gender,
      menu,
      kCalOptions[value][gender][menu][0].value
    )

    trackCustomEvent({
      category: "order",
      action: value,
      label: "HP",
    })
  }

  const onSetGender = value => {
    console.log("onSetGender triggered", value)
    setGender(value)
    setKcal(Number(kCalOptions[plan][value][menu][0].value))
    setFieldValue("kcal", Number(kCalOptions[plan][value][menu][0].value))
  }

  const onSetProgram = value => {
    console.log("onSetProgram triggered")
    setProgram(value)
    trackCustomEvent({
      category: "order",
      action: value,
      label: "HP",
    })
  }

  const onSetMenu = value => {
    console.log("onSetMenu", value)
    setMenu(value)
    setKcal(Number(kCalOptions[plan][gender][value][0].value))
    setFieldValue("kcal", Number(kCalOptions[plan][gender][value][0].value))
    if (value === "2chodové menu") {
      setKcalDisabled(true)
    } else {
      setKcalDisabled(false)
    }
    trackCustomEvent({
      category: "order",
      action: value,
      label: "HP",
    })
  }

  const onSetWeek = value => {
    console.log("set week triggered: ", value)
    setWeek(value)
    trackCustomEvent({
      category: "order",
      action: value,
      label: "HP",
    })
  }

  const getPrice = (menu, program, plan, kcal) => {
    let price = null
    const kcalRange = energyRange(kcal)
    price = pricePreset[menu][program][kcalRange]
    return price
  }

  const dateStyle = {
    height: "36px",
    padding: "7px 20px",
    fontSize: "18px",
    border: "none",
    borderRadius: 20,
    textAlign: "center",
    readOnly: true,
    backgroundColor: "#fff",
    outline: "none",
  }

  /*  step2, time range input  */
  const steps = [1, 2]
  const [currentStepIndex, setCurrentStepIndex] = useState(1)

  const handleInputChange = e => {
    setCurrentStepIndex(e.currentTarget.value)
    setDeliveryTime(getTimeRange(e.currentTarget.value))
    setFieldValue("deliveryTime", getTimeRange(e.currentTarget.value))
  }

  const getTimeRange = index => {
    switch (true) {
      case steps[index] === 1:
        return "18:00-20:00"
      case steps[index] === 2:
        return "20:00-22:00"
      default:
        return ""
    }
  }

  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate, affiliateId } = affiliateContext.affiliate

  useEffect(() => {
    const priceValue = getPrice(menu, program, plan, kcal)
    setPrice(priceValue)
    window.localStorage.setItem("price", priceValue);
  }, [menu, plan, program, week, kcal])

  useEffect(() => {
    onSetPlan("Zhubnout") // additionally trigger this default state | value
    window.localStorage.setItem("isDiscount", false);
    window.localStorage.removeItem("totalPrice");
  }, [])

  const handleChangeAffiliate = () => {
    console.log("handleChangeAffiliate affiliateId")
    console.log("handleChangeAffiliate isAffiliate")
    setFieldValue("isAffiliate", isAffiliate);
    setFieldValue("affiliateId", affiliateId);
  }

  useEffect(()  => {
    handleChangeAffiliate();
  }, [affiliateContext, isAffiliate, affiliateId])

  useEffect(() => {
    console.log("delivery changed in Form ", delivery);
  }, [delivery]);

  return (
    <div className={orderFormStyles.orderFormBox}>
      <RenderModal className="modalForm">
        <h2>Personenbezogene Daten</h2>
        <p>
        Ich stimme ausdrücklich der Verarbeitung und Speicherung meiner personenbezogenen Daten durch Nutritionpro s.r.o., Ident.-Nr. 07752971 in dem von mir bereitgestelltem Umfang zu.
          <br />
          Die Einwilligung kann jederzeit widerrufen werden, die Grundsätze zum Umgang mit personenbezogenen Daten und weitere Information sind verfügbar unter{" "}
          <a href="/dsgvo/" target="_blank">
            https://nutritionpro.cz/dsgvo/
          </a>
        </p>
      </RenderModal>
      <Form className={orderFormStyles.orderForm} name="order" method="post">
        <div className={orderFormStyles.orderFormInner}>
          <div className={orderFormStyles.orderFormProgress}>
            <div
              className={cx(orderFormStyles.orderFormProgressItem, {
                [orderFormStyles.active]: step === 1,
                [orderFormStyles.done]: step && step !== 1,
              })}
              onClick={() => gotoStep(1)}
            >
              <span>{t("home.order.step1title")}</span>
            </div>
            <div
              className={cx(orderFormStyles.orderFormProgressDivider, {
                [orderFormStyles.orderFormProgressDividerDone]:
                  step && step !== 1,
              })}
            >
              <span
                className={cx(orderFormStyles.orderFormProgressDividerItem, {
                  [orderFormStyles.xsHidden]: step === 2,
                })}
              ></span>
              <span
                className={orderFormStyles.orderFormProgressDividerItem}
              ></span>
              <span
                className={cx(orderFormStyles.orderFormProgressDividerItem, {
                  [orderFormStyles.xsHidden]: step === 2,
                })}
              ></span>
            </div>
            <div
              className={cx(orderFormStyles.orderFormProgressItem, {
                [orderFormStyles.active]: step === 2,
                [orderFormStyles.done]: step && step !== 1 && step !== 2,
              })}
              onClick={() => gotoStep(2)}
            >
              <span>{t("home.order.step2title")}</span>
            </div>
          </div>
          <div className={orderFormStyles.steps}>
            <div
              className={cx(orderFormStyles.step1, {
                [orderFormStyles.step1active]: step === 1,
              })}
            >
              <div className={cx(orderFormStyles.gender, orderFormStyles.box)}>
                <h5
                  className={cx(
                    mainFormStyles.inputFieldTitle,
                    orderFormStyles.step2InputTitle
                  )}
                >
                  {t("forms.onlineOrderFormGenderLabel")}
                </h5>
                <div
                  className={cx(
                    stylesRadio.radioBtns2,
                    orderFormStyles.step2InputWrap
                  )}
                >
                  <div
                    className={cx(
                      stylesRadio.radio,
                      stylesRadio.radioBtn,
                      orderFormStyles.step2radioBtn
                    )}
                  >
                    <input
                      id="gender1"
                      type="radio"
                      name="gender"
                      value="female"
                      checked={values.gender === "female"}
                      onChange={e => {
                        onSetGender(e.target.value)
                        setFieldValue("gender", "female")
                      }}
                    />

                    <label htmlFor="gender1">
                      <span className={orderFormStyles.step2radioBtnIcon}>
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.01563 6.18221C7.01007 13.6011 1.78896 17.6567 0.822293 21.2322C-0.144373 24.8078 -0.118819 32.8711 16.3278 37.3178C32.7745 41.7644 37.919 32.3167 38.9812 28.3833C43.9601 9.96999 12.5678 -6.95779 9.01563 6.18221Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M30.5555 32.7777H9.44439C8.21661 32.7777 7.22217 31.7833 7.22217 30.5555V9.44439C7.22217 8.21661 8.21661 7.22217 9.44439 7.22217H30.5555C31.7833 7.22217 32.7777 8.21661 32.7777 9.44439V30.5555C32.7777 31.7833 31.7833 32.7777 30.5555 32.7777Z"
                            fill="#F3F3F1"
                          />
                          <path
                            d="M20.0254 23.0533C23.3992 23.0533 26.1343 20.3183 26.1343 16.9445C26.1343 13.5706 23.3992 10.8356 20.0254 10.8356C16.6515 10.8356 13.9165 13.5706 13.9165 16.9445C13.9165 20.3183 16.6515 23.0533 20.0254 23.0533Z"
                            fill="#3DC383"
                          />
                          <path
                            d="M11.6666 32.7777H9.44439C8.22217 32.7777 7.22217 31.7777 7.22217 30.5555V9.44439C7.22217 8.22217 8.22217 7.22217 9.44439 7.22217H11.6666C10.4444 7.22217 9.44439 8.22217 9.44439 9.44439V30.5555C9.44439 31.7777 10.4444 32.7777 11.6666 32.7777Z"
                            fill="#D5DBE1"
                          />
                          <path
                            d="M21.1109 22.6778C20.7553 22.7444 20.3775 22.7778 19.9998 22.7778C16.622 22.7778 13.8887 20.0444 13.8887 16.6667C13.8887 13.2889 16.622 10.5555 19.9998 10.5555C20.3775 10.5555 20.7553 10.5889 21.1109 10.6555C18.2664 11.1778 16.1109 13.6667 16.1109 16.6667C16.1109 19.6667 18.2664 22.1556 21.1109 22.6778Z"
                            fill="#00B871"
                          />
                          <path
                            d="M30.5553 33.6111H9.44423C7.75867 33.6111 6.38867 32.2411 6.38867 30.5556V9.44447C6.38867 7.75892 7.75867 6.38892 9.44423 6.38892H30.5553C32.2409 6.38892 33.6109 7.75892 33.6109 9.44447V30.5556C33.6109 32.2411 32.2409 33.6111 30.5553 33.6111ZM9.44423 8.05558C8.67867 8.05558 8.05534 8.67892 8.05534 9.44447V30.5556C8.05534 31.3211 8.67867 31.9445 9.44423 31.9445H30.5553C31.3209 31.9445 31.9442 31.3211 31.9442 30.5556V9.44447C31.9442 8.67892 31.3209 8.05558 30.5553 8.05558H9.44423Z"
                            fill="#353643"
                          />
                          <path
                            d="M19.9996 23.6111C16.1707 23.6111 13.0552 20.4955 13.0552 16.6666C13.0552 12.8377 16.1707 9.72217 19.9996 9.72217C23.8285 9.72217 26.9441 12.8377 26.9441 16.6666C26.9441 20.4955 23.8285 23.6111 19.9996 23.6111ZM19.9996 11.3888C17.0896 11.3888 14.7218 13.7566 14.7218 16.6666C14.7218 19.5766 17.0896 21.9444 19.9996 21.9444C22.9096 21.9444 25.2774 19.5766 25.2774 16.6666C25.2774 13.7566 22.9096 11.3888 19.9996 11.3888Z"
                            fill="#353643"
                          />
                          <path
                            d="M19.1665 21.9445H20.8332V30.2778H19.1665V21.9445Z"
                            fill="#353643"
                          />
                          <path
                            d="M15.2778 25.2778H24.7223V26.9445H15.2778V25.2778Z"
                            fill="#353643"
                          />
                          <path
                            d="M31.6667 5.56221C30.7478 5.56221 30 4.81444 30 3.89555C30 2.97666 30.7478 2.22888 31.6667 2.22888C32.5856 2.22888 33.3333 2.97666 33.3333 3.89555C33.3333 4.81444 32.5856 5.56221 31.6667 5.56221ZM31.6667 3.33999C31.36 3.33999 31.1111 3.58888 31.1111 3.89555C31.1111 4.20221 31.36 4.4511 31.6667 4.4511C31.9733 4.4511 32.2222 4.20221 32.2222 3.89555C32.2222 3.58888 31.9733 3.33999 31.6667 3.33999Z"
                            fill="#A4AFC1"
                          />
                        </svg>
                      </span>
                      {t("forms.onlineOrderFormGenderFemale")}
                    </label>
                  </div>
                  <div
                    className={cx(
                      stylesRadio.radio,
                      stylesRadio.radioBtn,
                      orderFormStyles.step2radioBtn
                    )}
                  >
                    <input
                      id="gender2"
                      type="radio"
                      name="gender"
                      value="male"
                      checked={values.gender === "male"}
                      onChange={e => {
                        onSetGender(e.target.value)
                        setFieldValue("gender", "male")
                      }}
                    />
                    <label htmlFor="gender2">
                      <span className={orderFormStyles.step2radioBtnIcon}>
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M30.4637 4.3489C22.7803 4.49223 17.4281 0.610013 13.7259 0.680013C10.0237 0.750013 2.28589 3.02335 2.60478 20.0578C2.92367 37.0922 13.4314 39.3956 17.5048 39.32C36.5748 38.9633 44.0726 4.09446 30.4637 4.3489Z"
                            fill="#EFEFEF"
                          />
                          <path
                            d="M30.5555 32.7777H9.44439C8.21661 32.7777 7.22217 31.7833 7.22217 30.5555V9.44439C7.22217 8.21661 8.21661 7.22217 9.44439 7.22217H30.5555C31.7833 7.22217 32.7777 8.21661 32.7777 9.44439V30.5555C32.7777 31.7833 31.7833 32.7777 30.5555 32.7777Z"
                            fill="#F3F3F1"
                          />
                          <path
                            d="M17.2218 29.4444C20.9037 29.4444 23.8885 26.4596 23.8885 22.7777C23.8885 19.0958 20.9037 16.1111 17.2218 16.1111C13.5399 16.1111 10.5552 19.0958 10.5552 22.7777C10.5552 26.4596 13.5399 29.4444 17.2218 29.4444Z"
                            fill="#3DC383"
                          />
                          <path
                            d="M11.6666 32.7777H9.44439C8.22217 32.7777 7.22217 31.7777 7.22217 30.5555V9.44439C7.22217 8.22217 8.22217 7.22217 9.44439 7.22217H11.6666C10.4444 7.22217 9.44439 8.22217 9.44439 9.44439V30.5555C9.44439 31.7777 10.4444 32.7777 11.6666 32.7777Z"
                            fill="#D5DBE1"
                          />
                          <path
                            d="M18.333 29.3444C17.9774 29.4111 17.5996 29.4444 17.2218 29.4444C13.5441 29.4444 10.5552 26.4555 10.5552 22.7777C10.5552 19.1 13.5441 16.1111 17.2218 16.1111C17.5996 16.1111 17.9774 16.1444 18.333 16.2111C15.1774 16.7333 12.7774 19.4777 12.7774 22.7777C12.7774 26.0777 15.1774 28.8222 18.333 29.3444Z"
                            fill="#00B871"
                          />
                          <path
                            d="M30.5553 33.6111H9.44423C7.75867 33.6111 6.38867 32.2411 6.38867 30.5556V9.44447C6.38867 7.75892 7.75867 6.38892 9.44423 6.38892H30.5553C32.2409 6.38892 33.6109 7.75892 33.6109 9.44447V30.5556C33.6109 32.2411 32.2409 33.6111 30.5553 33.6111ZM9.44423 8.05558C8.67867 8.05558 8.05534 8.67892 8.05534 9.44447V30.5556C8.05534 31.3211 8.67867 31.9445 9.44423 31.9445H30.5553C31.3209 31.9445 31.9442 31.3211 31.9442 30.5556V9.44447C31.9442 8.67892 31.3209 8.05558 30.5553 8.05558H9.44423Z"
                            fill="#353643"
                          />
                          <path
                            d="M17.2222 30.2778C13.0866 30.2778 9.72217 26.9134 9.72217 22.7778C9.72217 18.6423 13.0866 15.2778 17.2222 15.2778C21.3577 15.2778 24.7222 18.6423 24.7222 22.7778C24.7222 26.9134 21.3577 30.2778 17.2222 30.2778ZM17.2222 16.9445C14.0055 16.9445 11.3888 19.5612 11.3888 22.7778C11.3888 25.9945 14.0055 28.6112 17.2222 28.6112C20.4388 28.6112 23.0555 25.9945 23.0555 22.7778C23.0555 19.5612 20.4388 16.9445 17.2222 16.9445Z"
                            fill="#353643"
                          />
                          <path
                            d="M30.2782 18.0555H28.6115V11.3888H21.9448V9.72217H29.4448C29.9048 9.72217 30.2782 10.0955 30.2782 10.5555V18.0555Z"
                            fill="#353643"
                          />
                          <path
                            d="M21.3511 17.4673L28.85 9.96838L30.0283 11.1467L22.5294 18.6456L21.3511 17.4673Z"
                            fill="#353643"
                          />
                          <path
                            d="M3.79655 7.03108C2.87766 7.03108 2.12988 6.28331 2.12988 5.36442C2.12988 4.44553 2.87766 3.69775 3.79655 3.69775C4.71544 3.69775 5.46322 4.44553 5.46322 5.36442C5.46322 6.28331 4.71544 7.03108 3.79655 7.03108ZM3.79655 4.80886C3.48988 4.80886 3.24099 5.05775 3.24099 5.36442C3.24099 5.67109 3.48988 5.91997 3.79655 5.91997C4.10322 5.91997 4.3521 5.67109 4.3521 5.36442C4.3521 5.05775 4.1021 4.80886 3.79655 4.80886Z"
                            fill="#A4AFC1"
                          />
                        </svg>
                      </span>
                      {t("forms.onlineOrderFormGenderMale")}
                    </label>
                  </div>
                </div>
              </div>
              <div className={cx(orderFormStyles.cel)}>
                <h5
                  className={cx(
                    mainFormStyles.inputFieldTitle,
                    orderFormStyles.celTitle
                  )}
                >
                  {t("forms.mainFormGoalLabel")}
                </h5>
                <div className={cx(orderFormStyles.celItems)}>
                  <div
                    className={cx(
                      stylesRadio.radio,
                      stylesRadio.radioBtn,
                      orderFormStyles.radioBtn
                    )}
                  >
                    <input
                      id="plan1"
                      type="radio"
                      name="plan"
                      value="Zhubnout"
                      checked={values.plan === "Zhubnout"}
                      onChange={e => {
                        onSetPlan(e.target.value)
                        setFieldValue("plan", "Zhubnout")
                      }}
                    />

                    <label htmlFor="plan1">
                      <img
                        src={zhubnostIcon}
                        className={stylesRadio.img}
                        alt="icon"
                      />
                      {t("forms.mainFormGoalOption1")}
                    </label>
                  </div>
                  <div
                    className={cx(
                      stylesRadio.radio,
                      stylesRadio.radioBtn,
                      orderFormStyles.radioBtn
                    )}
                  >
                    <input
                      id="plan2"
                      type="radio"
                      name="plan"
                      value="Udržovat"
                      checked={values.plan === "Udržovat"}
                      onChange={e => {
                        onSetPlan(e.target.value)
                        setFieldValue("plan", "Udržovat")
                      }}
                    />
                    <label htmlFor="plan2">
                      <img
                        src={udrzovatIcon}
                        className={stylesRadio.img}
                        alt="icon"
                      />
                      {t("forms.mainFormGoalOption2")}
                    </label>
                  </div>
                  <div
                    className={cx(
                      stylesRadio.radio,
                      stylesRadio.radioBtn,
                      orderFormStyles.radioBtn
                    )}
                  >
                    <input
                      id="plan3"
                      type="radio"
                      name="plan"
                      value="Nabírat"
                      checked={values.plan === "Nabírat"}
                      onChange={e => {
                        onSetPlan(e.target.value)
                        setFieldValue("plan", "Nabírat")
                      }}
                    />
                    <label htmlFor="plan3">
                      <img
                        src={nabiratIcon}
                        className={stylesRadio.img}
                        alt="icon"
                      />
                      {t("forms.mainFormGoalOption3")}
                    </label>
                  </div>
                  <div
                    className={cx(
                      stylesRadio.radio,
                      stylesRadio.radioBtn,
                      orderFormStyles.radioBtn
                    )}
                  >
                    <input
                      id="plan4"
                      type="radio"
                      name="plan"
                      value="Office pack"
                      checked={values.plan === "Office pack"}
                      onChange={e => {
                        onSetPlan(e.target.value)
                        setFieldValue("plan", "Office pack")
                      }}
                    />
                    <label htmlFor="plan4">
                      <svg
                        className={stylesRadio.img}
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.4637 4.3489C22.7803 4.49223 17.4281 0.610013 13.7259 0.680013C10.0237 0.750013 2.28589 3.02335 2.60478 20.0578C2.92367 37.0922 13.4314 39.3956 17.5048 39.32C36.5748 38.9633 44.0726 4.09446 30.4637 4.3489Z"
                          fill="#EFEFEF"
                        />
                        <path
                          d="M22.0835 5.54004H24.3057V6.65115H22.0835V5.54004Z"
                          fill="#A4AFC1"
                        />
                        <path
                          d="M15.6948 5.54004H17.917V6.65115H15.6948V5.54004Z"
                          fill="#A4AFC1"
                        />
                        <path
                          d="M19.5835 1.65112H20.6946V3.87335H19.5835V1.65112Z"
                          fill="#A4AFC1"
                        />
                        <path
                          d="M8.61149 14.7222C7.6926 14.7222 6.94482 13.9745 6.94482 13.0556C6.94482 12.1367 7.6926 11.3889 8.61149 11.3889C9.53038 11.3889 10.2782 12.1367 10.2782 13.0556C10.2782 13.9745 9.53038 14.7222 8.61149 14.7222ZM8.61149 12.5C8.30482 12.5 8.05593 12.75 8.05593 13.0556C8.05593 13.3611 8.30482 13.6111 8.61149 13.6111C8.91816 13.6111 9.16704 13.3611 9.16704 13.0556C9.16704 12.75 8.91816 12.5 8.61149 12.5Z"
                          fill="#A4AFC1"
                        />
                        <path
                          d="M30.278 31.3888H9.72244L8.61133 26.9443H31.3891L30.278 31.3888Z"
                          fill="#F3F3F1"
                        />
                        <path
                          d="M20.0002 14.1666C13.7091 14.1666 8.61133 19.7066 8.61133 25.8588C8.61133 26.0388 8.61133 26.2088 8.62244 26.3888H31.378C31.3891 26.2088 31.3891 26.0388 31.3891 25.8588C31.3891 19.7066 26.2913 14.1666 20.0002 14.1666Z"
                          fill="#44C789"
                        />
                        <path
                          d="M20.0002 11.3889C20.7673 11.3889 21.3891 10.767 21.3891 9.99997C21.3891 9.23291 20.7673 8.61108 20.0002 8.61108C19.2332 8.61108 18.6113 9.23291 18.6113 9.99997C18.6113 10.767 19.2332 11.3889 20.0002 11.3889Z"
                          fill="#44C789"
                        />
                        <path
                          d="M8.61133 26.9446L9.72244 31.389H12.2224L11.1113 26.9446H8.61133Z"
                          fill="#D5DBE1"
                        />
                        <path
                          d="M21.2502 14.2445C20.8391 14.1956 20.4236 14.1667 20.0002 14.1667C13.7091 14.1667 8.61133 19.7067 8.61133 25.859C8.61133 26.039 8.61133 26.209 8.62244 26.389H11.1224C11.1113 26.209 11.1113 26.039 11.1113 25.859C11.1113 20.1201 15.548 14.919 21.2502 14.2445Z"
                          fill="#00B871"
                        />
                        <path
                          d="M30.2774 32.2222H9.7218C9.33958 32.2222 9.00625 31.9622 8.91292 31.5911L7.8018 27.1466C7.73958 26.8977 7.79514 26.6344 7.95292 26.4311C8.1118 26.2289 8.35403 26.1111 8.61069 26.1111H31.3885C31.6451 26.1111 31.8874 26.2289 32.0451 26.4311C32.2029 26.6344 32.2585 26.8977 32.1963 27.1466L31.0851 31.5911C30.9929 31.9622 30.6596 32.2222 30.2774 32.2222ZM10.3718 30.5555H29.6263L30.3218 27.7777H9.67736L10.3718 30.5555Z"
                          fill="#353643"
                        />
                        <path
                          d="M32.2101 26.4389L30.5467 26.3389C30.5567 26.1756 30.5556 26.0211 30.5556 25.8578C30.5556 19.9723 25.7212 15 20.0001 15C14.2789 15 9.4445 19.9734 9.4445 25.8578C9.4445 26.02 9.4445 26.1745 9.45339 26.3389L7.79005 26.4389C7.77783 26.2422 7.77783 26.0556 7.77783 25.8578C7.77783 19.0689 13.3756 13.3334 20.0001 13.3334C26.6245 13.3334 32.2223 19.0689 32.2223 25.8578C32.2223 26.0556 32.2223 26.2422 32.2101 26.4389Z"
                          fill="#353643"
                        />
                        <path
                          d="M19.1665 11.6667H20.8332V13.889H19.1665V11.6667Z"
                          fill="#353643"
                        />
                        <path
                          d="M20.0001 12.2223C18.7745 12.2223 17.7778 11.2256 17.7778 10.0001C17.7778 8.7745 18.7745 7.77783 20.0001 7.77783C21.2256 7.77783 22.2223 8.7745 22.2223 10.0001C22.2223 11.2256 21.2256 12.2223 20.0001 12.2223ZM20.0001 9.4445C19.6934 9.4445 19.4445 9.6945 19.4445 10.0001C19.4445 10.3056 19.6934 10.5556 20.0001 10.5556C20.3067 10.5556 20.5556 10.3056 20.5556 10.0001C20.5556 9.6945 20.3067 9.4445 20.0001 9.4445Z"
                          fill="#353643"
                        />
                        <path
                          d="M28.8893 23.6112H27.2226C27.2226 20.7012 24.8548 18.3334 21.9448 18.3334V16.6667C25.7737 16.6667 28.8893 19.7823 28.8893 23.6112Z"
                          fill="#353643"
                        />
                        <path
                          d="M7.5 26.1111H32.5V27.7777H7.5V26.1111Z"
                          fill="#353643"
                        />
                      </svg>

                      {t("forms.mainFormGoalOption4")}
                    </label>
                  </div>
                </div>
              </div>
              <div className={cx(orderFormStyles.menu)}>
                <h5
                  className={cx(
                    mainFormStyles.inputFieldTitle,
                    orderFormStyles.menuTitle
                  )}
                >
                  {t("forms.mainFormMenuLabel")}
                </h5>
                <Select
                  className={cx(form2Styles.input, form2Styles.select)}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: "100%",
                      borderRadius: "40px",
                      border: "none",
                      paddingLeft: "12px",
                      whiteSpace: "nowrap",
                      background: "#F8F8F8",
                    }),
                    placeholder: defaultStyles => {
                      return {
                        ...defaultStyles,
                        color: "#d5dbe1",
                      }
                    },
                  }}
                  options={[
                    {
                      value: "5chodové menu",
                      label: t("forms.mainFormMenuOption1"),
                      isdisabled: menu5xDisabled,
                    },
                    {
                      value: "4chodové menu",
                      label: t("forms.mainFormMenuOption4"),
                      isdisabled: menu4xDisabled,
                    },
                    {
                      value: "3chodové menu",
                      label: t("forms.mainFormMenuOption2"),
                      isdisabled: menu3xDisabled,
                    },
                    {
                      value: "2chodové menu",
                      label: t("forms.mainFormMenuOption3"),
                      isdisabled: menu2xDisabled,
                    },
                  ]}
                  isOptionDisabled={option => option.isdisabled}
                  isSearchable={false}
                  value={{
                    value: values.menu,
                    label: getMenuSelectLabel(values.menu),
                  }}
                  defaultValue={menu}
                  onChange={e => {
                    onSetMenu(e.value)
                    setFieldValue("menu", e.value)
                  }}
                />
              </div>
              <div className={orderFormStyles.kal}>
                <h5
                  className={cx(
                    mainFormStyles.inputFieldTitle,
                    orderFormStyles.kalTitle
                  )}
                >
                  {t("home.order.kalLabel")}
                </h5>
                <div>
                  <Select
                    className={cx(form2Styles.input, form2Styles.select)}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        height: "100%",
                        borderRadius: "40px",
                        border: "none",
                        paddingLeft: "12px",
                        whiteSpace: "nowrap",
                        background: "#F8F8F8",
                      }),
                      placeholder: defaultStyles => {
                        return {
                          ...defaultStyles,
                          color: "#d5dbe1",
                        }
                      },
                    }}
                    options={kCalOptions[plan][gender][menu]}
                    isSearchable={false}
                    value={{ value: kcal, label: `${kcal} kCal` }}
                    isDisabled={kcalDisabled}
                    onChange={e => {
                      onSetKcal(e.value)
                      setFieldValue("kcal", e.value)
                    }}
                  />
                </div>
              </div>
              <div className={cx(orderFormStyles.program)}>
                <RadioButtonGroup
                  id="radioGroup"
                  value={values.program}
                  error={errors.program}
                  touched={touched.program}
                  onChange={e => {
                    onSetProgram(e.target.value)
                  }}
                  className={cx(
                    stylesRadio.radioGroup,
                    orderFormStyles.radioGroup
                  )}
                >
                  <h5
                    className={cx(
                      mainFormStyles.inputFieldTitle,
                      orderFormStyles.programTitle
                    )}
                  >
                    {t("forms.mainFormProgramLabel2")}
                  </h5>
                  <div className={stylesRadio.radioPanel}>
                    <FastField
                      component={RadioButton}
                      name="program"
                      id="option4"
                      label="1 week"
                      title={t("forms.mainFormProgramOption4")}
                    />
                    <FastField
                      component={RadioButton}
                      name="program"
                      id="option1"
                      label="2 týdny"
                      title={t("forms.mainFormProgramOption1")}
                    />
                    <FastField
                      component={RadioButton}
                      name="program"
                      id="option2"
                      label="Měsíc"
                      title={t("forms.mainFormProgramOption2")}
                    />
                    <FastField
                      component={RadioButton}
                      name="program"
                      id="option3"
                      label="Dva měsíce"
                      title={t("forms.mainFormProgramOption3")}
                    />
                  </div>
                </RadioButtonGroup>
              </div>
              <div className={orderFormStyles.week}>
                <div className={cx(stylesRadio.radioBtns2)}>
                  <RadioButtonGroup
                    id="radioGroup2"
                    value={values.week}
                    error={errors.week}
                    touched={touched.week}
                    onChange={e => {
                      onSetWeek(e.target.value)
                    }}
                    className={cx(
                      stylesRadio.radioGroup,
                      orderFormStyles.radioGroup
                    )}
                  >
                    <h5
                      className={cx(
                        mainFormStyles.inputFieldTitle,
                        orderFormStyles.weekTitle
                      )}
                    >
                      {t("home.order.weekLabel")}
                    </h5>
                    <div className={stylesRadio.radioPanel}>
                      <FastField
                        component={RadioButton}
                        name="week"
                        id="weekoption1"
                        label={`5`}
                        title={t("home.order.week5")}
                      />
                      <FastField
                        component={RadioButton}
                        name="week"
                        id="weekoption2"
                        label={`6`}
                        title={t("home.order.week6")}
                      />
                    </div>
                  </RadioButtonGroup>
                </div>
              </div>

              <div
                className={cx(orderFormStyles.priceCell1, orderFormStyles.box)}
              >
                <div className={orderFormStyles.price}>
                  <Price2
                    price={price}
                    totalPrice={values.totalPrice}
                    plan={plan}
                    program={program}
                    week={week}
                    // promo={promo}
                    delivery={delivery}
                    deliveryPrice={values.deliveryPrice}
                    handleSetDelivery={handleSetDelivery}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className={orderFormStyles.btn}>
                  <Button2
                    color="secondary"
                    isBlock={true}
                    className="text-center justify-center"
                    handleClick={gotoNextStep}
                  >
                    {t("home.order.ctaNext")}
                  </Button2>
                </div>
              </div>
            </div>

            <div
              className={cx(orderFormStyles.step2, {
                [orderFormStyles.step2active]: step === 2,
              })}
            >
              <div className={orderFormStyles.terms}>
                <div className={orderFormStyles.checkTerms}>
                  {/* <input
                    id="checkTerms"
                    type="checkbox"
                    name="checkTerms"
                    checked={checkTerms}
                    onChange={e => {
                      setCheckTerms(e.target.checked)
                      console.log(e.target.checked)
                    }}
                  /> */}
                  {/* <FastField
                    type="checkbox"
                    id="checkTerms"
                    name="checkTerms"
                  />
                  <label htmlFor="checkTerms">
                    {t("forms.mainFormCheckTerms1Label")}{" "}
                    <a href="/terms" target="_blank">
                      {t("forms.mainFormCheckTerms2Label")}
                    </a>
                  </label> */}
                  <CustomCheckbox id="checkTerms" name="checkTerms">
                    {t("forms.mainFormCheckTerms1Label")}{" "}
                    <a href="/terms" target="_blank">
                      {t("forms.mainFormCheckTerms2Label")}
                    </a>
                  </CustomCheckbox>
                  <ErrorMessage
                    component="div"
                    className={orderFormStyles.inputError}
                    name="checkTerms"
                  />
                </div>

                <div className={orderFormStyles.checkTerms}>
                  {/* <input
                    id="checkTerms2"
                    type="checkbox"
                    name="checkTerms2"
                    checked={checkTerms2}
                    onChange={e => {
                      setCheckTerms2(e.target.checked)
                    }}
                  /> */}
                  {/* <FastField
                    type="checkbox"
                    id="checkTerms2"
                    name="checkTerms2"
                  />
                  <label htmlFor="checkTerms2">
                    {t("forms.mainFormCheckTerms3Label")}
                  </label> */}
                  <CustomCheckbox id="checkTerms2" name="checkTerms2">
                    {/* <Trans i18nKey="forms.mainFormCheckTerms3Label">
                      Souhlasím se zpracováním
                      <button
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          background: "none",
                          border: "none",
                          padding: 0,
                          font: "inherit",
                        }}
                        onClick={show}
                      >
                        osobních údajů
                      </button>
                    </Trans> */}
                    {t("forms.mainFormCheckTerms3Label")}{" "}
                      <button
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          background: "none",
                          border: "none",
                          padding: 0,
                          font: "inherit",
                        }}
                        onClick={show}
                      >
                        {t("forms.mainFormCheckTerms4Label")}
                      </button>
                  </CustomCheckbox>
                  <ErrorMessage
                    component="div"
                    className={orderFormStyles.inputError}
                    name="checkTerms2"
                  />
                </div>
              </div>

              <div className={orderFormStyles.deliveryTime}>
                <h5
                  className={cx(
                    mainFormStyles.inputFieldTitle,
                    orderFormStyles.step2InputTitle
                  )}
                >
                  <Trans i18nKey="forms.onlineOrderFormDeliveryTimeLabel">
                  Bevorzugter Zeitpunkt der Lieferung
                  </Trans>
                  
                </h5>
                <div className={orderFormStyles.deliveryTimeStepper}>
                  <div className={orderFormStyles.deliveryTimeStepperLines}>
                    <div className={orderFormStyles.deliveryTimeStepperLine}>
                      18:00
                    </div>
                    <div className={orderFormStyles.deliveryTimeStepperLine}>
                      19:00
                    </div>
                    <div className={orderFormStyles.deliveryTimeStepperLine}>
                      20:00
                    </div>
                    <div className={orderFormStyles.deliveryTimeStepperLine}>
                      21:00
                    </div>
                    <div className={orderFormStyles.deliveryTimeStepperLine}>
                      22:00
                    </div>
                  </div>
                  <div className={orderFormStyles.deliveryTimeStepperInput}>
                    <input
                      onChange={handleInputChange}
                      type="range"
                      min="0"
                      value={currentStepIndex}
                      max="1"
                      step="1"
                      list="tick-list"
                    />
                    <datalist id="tick-list">
                      <option>0</option>
                      <option>1</option>
                    </datalist>
                  </div>
                  <div className={orderFormStyles.deliveryTimeStepperResult}>
                    {getTimeRange(currentStepIndex)}
                  </div>
                </div>
              </div>
              <div className={cx(orderFormStyles.day)}>
                <h5
                  className={cx(
                    mainFormStyles.inputFieldTitle,
                    orderFormStyles.step2InputTitle
                  )}
                >
                  {t("forms.onlineOrderFormOrderstartLabel")}
                </h5>
                <div
                  className={cx(
                    stylesRadio.radioBtns,
                    orderFormStyles.dayPicker
                  )}
                >
                  <div className={cx(orderFormStyles.dayPickerInner)}>
                    <DayPickerInput
                      // locale={de}
                      inputProps={{ style: dateStyle, readOnly: true }}
                      onDayChange={day => {
                        setFieldValue(
                          "date",
                          moment(dateToSystemFormat(day)).toISOString()
                        )
                      }}
                      formatDate={e =>
                        lang === "de"
                          ? formatDate(e, "DD.MM.YYYY", "de")
                          : formatDate(e, "DD.MM.YYYY", "en")
                      }
                      parseDate={parseDate}
                      value={`${
                        lang === "de"
                          ? formatDate(values.date, "DD.MM.YYYY", "de")
                          : formatDate(values.date, "DD.MM.YYYY", "en")
                      }`}
                      placeholder={`${
                        lang === "de"
                          ? formatDate(values.date, "DD.MM.YYYY", "de")
                          : formatDate(values.date, "DD.MM.YYYY", "en")
                      }`}
                      dayPickerProps={{
                        locale: "de" ? "de" : "en-gb",
                        localeUtils: MomentLocaleUtils,
                        disabledDays: [
                          { daysOfWeek: [0, 1, 3, 5] },
                          {
                            before: moment().add(2, "days").toDate() // ,new Date("2022-07-16")
                          },
                        ],
                        selectedDays: moment(values.date).toDate(),
                      }}
                      hideOnDayClick
                    />
                  </div>
                </div>
              </div>
              <div className={orderFormStyles.contact}>
                <div className={orderFormStyles.step2contact}>
                  <div className={styles.inputField}>
                    <label
                      className={cx(
                        mainFormStyles.inputFieldTitle,
                        orderFormStyles.step2InputTitle
                      )}
                    >
                      {t("forms.onlineOrderFormNamesLabel")}
                    </label>
                    <FastField
                      innerRef={nameRef}
                      component="input"
                      type="text"
                      name="name"
                      className={orderFormStyles.input}
                      placeholder={t("forms.onlineOrderFormNamesLabel")}
                    />
                    {touched.name && errors.name && (
                      <span className={styles.error}>{errors.name}</span>
                    )}
                  </div>
                  <div className={styles.inputField}>
                    <label
                      className={cx(
                        mainFormStyles.inputFieldTitle,
                        orderFormStyles.step2InputTitle
                      )}
                    >
                      {t("forms.onlineOrderFormNumberLabel")}
                    </label>
                    {/* <FastField
                      innerRef={phoneRef}
                      component="input"
                      type="text"
                      name="phone"
                      className={orderFormStyles.input}
                      placeholder={t("forms.onlineOrderFormNumberLabel")}
                    /> */}
                    <MaskedPhoneInput
                      innerRef={phoneRef}
                      name="phone"
                      value={values.phone}
                      error={errors.phone}
                      onChange={(value) => {
                          setFieldValue("phone", value)
                        }
                      }
                      placeholder={t("forms.onlineOrderFormNumberLabel")}
                    />
                    {touched.phone && errors.phone && (
                      <span className={styles.error}>{errors.phone}</span>
                    )}
                  </div>
                  <div className={styles.inputField}>
                    <label
                      className={cx(
                        mainFormStyles.inputFieldTitle,
                        orderFormStyles.step2InputTitle
                      )}
                    >
                      {t("forms.onlineOrderFormEmailLabel")}
                    </label>
                    <FastField
                      innerRef={emailRef}
                      component="input"
                      type="text"
                      name="email"
                      className={orderFormStyles.input}
                      placeholder={t("forms.onlineOrderFormEmailLabel")}
                      onChange={e => handleChange("email", e)}
                    />
                    {touched.email && errors.email && (
                      <span className={styles.error}>{errors.email}</span>
                    )}
                  </div>
                  <div className={styles.inputField}>
                    <label
                      htmlFor="address"
                      className={cx(
                        mainFormStyles.inputFieldTitle,
                        orderFormStyles.step2InputTitle
                      )}
                    >
                      {t("forms.onlineOrderFormAddressLabel")}
                    </label>
                    {/* <DeliveryAddressInput
                      ref={addressRef}
                      setFieldValue={setFieldValue}
                      // price={price}
                      setPrice={setPrice}
                    /> */}
                    <GoogleSearchAddressInput
                      ref={addressRef}
                      setFieldValue={setFieldValue}
                      setPrice={setPrice}
                      handleSetDelivery={handleSetDelivery}
                    />
                    {/* <AutoComplete
                      apiKey={"AIzaSyCSyAn6Zke9iUXUgcLUhxz3FOPJ-fQCMss"}
                      onPlaceSelected={(place) => console.log("place", place)}
                    /> */}
                    {/* <GooglePlacesAutocomplete
                      apiKey="AIzaSyCSyAn6Zke9iUXUgcLUhxz3FOPJ-fQCMss"
                      selectProps={{
                        value,
                        onChange: setValue,
                      }}
                    /> */}
                    {/* <FastField
                      innerRef={addressRef}
                      component="input"
                      type="text"
                      name="address"
                      className={orderFormStyles.input}
                      placeholder={t("forms.onlineOrderFormAddressLabel")}
                    /> */}
                    {touched.address && errors.address && (
                      <span className={styles.error}>{errors.address}</span>
                    )}
                  </div>
                  <div className={cx(styles.inputField)}>
                    <label
                        htmlFor="promo"
                        className={cx(
                            mainFormStyles.inputFieldTitle,
                            orderFormStyles.step2InputTitle
                        )}
                    >
                      {t("forms.onlineOrderFormPromoCodeLabel")}
                    </label>
                    {/* <FastField
                      component="input"
                      type="text"
                      name="promo"
                      className={cx(
                        orderFormStyles.input,
                        orderFormStyles.inputPromo
                      )}
                      placeholder={t("forms.onlineOrderFormPromoCodeLabel")}
                    /> */}
                    <PromoInput
                        ref={promoRef}
                        setFieldValue={setFieldValue}
                        setPrice={setPrice}
                        address={values.address}
                        program={values.program}
                        week={values.week}
                    />
                    {touched.promo && errors.promo && (
                        <span className={styles.error}>{errors.promo}</span>
                    )}
                  </div>
                  <div className={styles.inputField}>
                    <h5
                        className={cx(
                            mainFormStyles.inputFieldTitle,
                            orderFormStyles.menuTitle
                        )}
                    >
                      {t("forms.payment.title")}
                    </h5>
                    <Select
                        className={cx(form2Styles.input, form2Styles.input_sm, form2Styles.select)}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            height: "100%",
                            borderRadius: "40px",
                            border: "none",
                            paddingLeft: "12px",
                            whiteSpace: "nowrap",
                            background: "#F8F8F8",
                          }),
                          placeholder: defaultStyles => {
                            return {
                              ...defaultStyles,
                              color: "#d5dbe1",
                            }
                          },
                        }}
                        options={[
                          {
                            value: "card",
                            label: t("forms.payment.label1"),
                          },
                          {
                            value: "stripe-card",
                            label: t("forms.payment.label2"),
                          },
                          {
                            value: "stripe-klarna",
                            label: t("forms.payment.label3"),
                          },
                          {
                            value: "stripe-giropay",
                            label: t("forms.payment.label4"),
                          },
                          {
                            value: "stripe",
                            label: t("forms.payment.label5"),
                          },
                          {
                            value: "invoice",
                            label: t("forms.payment.label6"),
                          },
                        ]}
                        isOptionDisabled={option => option.isdisabled}
                        isSearchable={false}
                        value={{
                          value: values.onlinePayment,
                          label: getPaymentMethodSelectLabel(values.onlinePayment),
                        }}
                        defaultValue={paymentMethod}
                        onChange={e => {
                          setFieldValue("onlinePayment", e.value)
                          setPaymentMethod(e.value)
                        }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={cx(orderFormStyles.priceCell, orderFormStyles.box)}
              >
                <div className={orderFormStyles.price}>
                  <Price2
                    price={price}
                    totalPrice={values.totalPrice}
                    plan={plan}
                    program={program}
                    week={week}
                    delivery={delivery}
                    deliveryPrice={values.deliveryPrice}
                    handleSetDelivery={handleSetDelivery}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className={orderFormStyles.submitBtnDesktop}>
                  <Button2
                      name="submit"
                      color="secondary"
                      buttonType="submit"
                      isBlock={true}
                      disabled={isSubmitting}
                      className="text-center justify-center"
                      handleClick={() => {
                        setFieldValue("isTwentyPercentPayment", false);
                        console.log("click submit!", "isTwentyPercentPayment", false)
                      }}
                      style={{whiteSpace: "normal"}}
                  >
                    {t("forms.onlineOrderFormCTAWithoutDiscount1")}
                    <br/>
                    {t("forms.onlineOrderFormCTAWithoutDiscount2")}
                  </Button2>
                  {/* <Button2
                    name="submit"
                    hasShadow={true}
                    color="secondaryOutline"
                    size="sm"
                    buttonType="submit"
                    isBlock={true}
                    disabled={isSubmitting}
                    className="text-center justify-center"
                    handleClick={() => {
                      setFieldValue("isTwentyPercentPayment", true);
                      console.log("click submit!", "isTwentyPercentPayment", true)
                    }}
                  >
                    {t("forms.onlineOrderFormCTAWithDiscount")}
                  </Button2> */}
                </div>
              </div>
              <div className={orderFormStyles.submitBtn}>
                <Button2
                    name="submit"
                    color="secondary"
                    buttonType="submit"
                    isBlock={true}
                    disabled={isSubmitting}
                    className="text-center justify-center"
                    handleClick={() => {
                      setFieldValue("isTwentyPercentPayment", false);
                      console.log("click submit!", "isTwentyPercentPayment", false)
                    }}
                    style={{whiteSpace: "normal"}}
                >
                  {t("forms.onlineOrderFormCTAWithoutDiscount1")}
                  <br/>
                  {t("forms.onlineOrderFormCTAWithoutDiscount2")}
                </Button2>
                {/* <Button2
                    name="submit"
                    hasShadow={true}
                    color="secondaryOutline"
                    size="sm"
                    buttonType="submit"
                    isBlock={true}
                    disabled={isSubmitting}
                    className="text-center justify-center"
                    handleClick={() => {
                      setFieldValue("isTwentyPercentPayment", true);
                      console.log("click submit!", "isTwentyPercentPayment", true)
                    }}
                >
                  {t("forms.onlineOrderFormCTAWithDiscount")}
                </Button2> */}
              </div>
              <input type="hidden" name="price" value={price} />
            </div>
            <Field
              component="input"
              type="hidden"
              name="isAddressValid"
              value={values.isAddressValid}
            />
            <Field
              component="input"
              type="hidden"
              name="totalPrice"
              value={values.totalPrice}
            />
            <Field
              component="input"
              type="hidden"
              name="deliveryPrice"
              value={delivery}
            />
            <Field
              component="input"
              type="hidden"
              name="isAffiliate"
              value={isAffiliate}
            />
            <Field
              component="input"
              type="hidden"
              name="affiliateId"
              value={affiliateId}
            />
            {/*<Field*/}
            {/*    component="input"*/}
            {/*    type="hidden"*/}
            {/*    name="isTwentyPercentPayment"*/}
            {/*    value={isTwentyPercentPayment}*/}
            {/*/>*/}
            {/* <input type="hidden" name="isAffiliate" value={isAffiliate} /> */}
          </div>
        </div>
      </Form>
    </div>
  )
}

const days = {
  "2 týdny": {
    5: 10,
    6: 12,
  },
  Měsíc: {
    5: 20,
    6: 24,
  },
  "Dva měsíce": {
    5: 40,
    6: 48,
  },
  "1 week": {
    5: 5,
    6: 6,
  },
}

export const OrderForm2 = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    phone: "+49",
    name: "",
    email: "",
    promo: "",
    plan: "Zhubnout",
    program: "Měsíc",
    menu: "5chodové menu",
    onlinePayment: "card",
    week: "6",
    kcal: 1600,
    date: getStartDate(), // new Date("2022-07-16"),
    deliveryTime: "18:00-20:00",
    deliveryPrice: 0,
    totalPrice: 0,
    gender: "female",
    address: "",
    checkTerms: false,
    checkTerms2: false,
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
    referrer: "",
    ga: "",
    success: false,
    isAffiliate: false,
    affiliateId: null,
    isAddressValid: false,
    isTwentyPercentPayment: false,
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(12, <PhoneErrorMessage />)
        .phone()
        .required(),
      promo: Yup.string().nullable(),
      email: Yup.string()
        .email(<EmailErrorMessage />)
        .required(<EmailErrorRequiredMessage />),
      name: Yup.string()
        .min(4)
        .required(<NameErrorRequiredMessage />),
      address: Yup.string().required(<AddressErrorMessage />),
      plan: Yup.string(),
      program: Yup.string(),
      menu: Yup.string(),
      checkTerms: Yup.bool().oneOf([true], <TermsErrorRequiredMessage />),
      checkTerms2: Yup.bool().oneOf([true], <Terms2ErrorRequiredMessage />),
    }),
  handleSubmit: async (
    {
      phone,
      promo,
      plan,
      program,
      menu,
      onlinePayment,
      week,
      kcal,
      date,
      name,
      email,
      address,
      deliveryTime,
      deliveryPrice,
      totalPrice,
      checkTerms,
      checkTerms2,
      isAffiliate,
      affiliateId,
      isAddressValid,
      isTwentyPercentPayment,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      console.log("form deliveryPrice", deliveryPrice);

      let getPrice = Number(document.querySelector('[name="price"]').value)  + +deliveryPrice // TODO: check additionally if its necessary to add deloveryPrice here (google search address input do it already?)

      const isEn = document.location.pathname.includes("/en")
      const testovani = localStorage.getItem("PUSHTELL-homepage")

      const diet =
        plan === "Zhubnout" ? "loose" : plan === "Nabírat" ? "gain" : "keep"
      let data = {
        form_name: isEn ? "online-order_en" : "online-order",
        language: isEn ? "EN" : "DE",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        phone: formatPhoneNumber(phone),
        promocode: promo,
        diet,
        length: days[program][week],
        mealsPerDay: menu[0],
        size: Number(week) === 6 ? "long" : "short",
        kcal,
        date,
        name,
        email,
        address,
        deliveryTime,
        price: getPrice,
        paymentMethod: onlinePayment.includes("stripe") ? "stripe" : onlinePayment,
        checkTerms,
        checkTerms2,
        cjevent: affiliateId,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
        testovani: testovani,
        isTwentyPercentPayment,
      }
      
      let isAddDeliveryFee = false;
      let TYurl = "";

      function checkDateOfOrder(date) {

      }

      await console.log("-- data: ", JSON.stringify(data));

      const reqDeliveryAddress = await fetch(`${process.env.GATSBY_APP_API_URL}/delivery/check-address`, {
        // const req = await fetch("https://test.nutritionpro.cz/api/app/order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          "q": address?.value?.description
        }),
      })

      await console.log("reqDeliveryAddress", JSON.stringify(data));
      const deliveryAddressResponse = await reqDeliveryAddress.json()
      await console.log("reqDeliveryAddress json", deliveryAddressResponse);
      isAddDeliveryFee = await deliveryAddressResponse?.result?.isPointInPolygon
      // TYurl = await deliveryAddressResponse?.result?.address?.url

      data = await {...data, ...{ address: address.value.description, isAddDeliveryFee: !isAddDeliveryFee }};

      if (!isAddressValid) {
        return;
      }

      await console.log("-- merged data: ", JSON.stringify(data));
      // await console.log("TY url", TYurl);

      const req = await fetch(`${process.env.GATSBY_APP_API_URL}/app/order`, {
        // const req = await fetch("https://test.nutritionpro.cz/api/app/order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      // // await setPrice("420")
      await console.log("data", JSON.stringify(data))

      await console.log("isAffiliate", isAffiliate);
      await console.log("affiliateId form submit", affiliateId);
      // await console.log("TYurl", TYurl);

      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)
        const response = await req.json()
        await console.log("data req", data.mealsPerDay, response);
        TYurl = await response?.onlinePayment?.gw_url
        await console.log("TYurl", TYurl);
        await console.log("response", response);
        
        setTimeout(() => {
          // resetForm()
          // setFieldValue("address", "");
          window.localStorage.removeItem("price");
          window.localStorage.removeItem("deliveryPrice");
          window.localStorage.removeItem("totalPrice");
          // document.querySelector('[name="price"]').value = 420;
          // document.querySelector("#price").textContent = 420;
          // // window.location.href = isEn ? "/en/thank-you" : "/dekovacka-testdrive"
          // window.location.href = TYurl ? TYurl : isEn ? "/en/thank-you" : "/dekovacka-testdrive"
          if (TYurl) {
            window.location.href = TYurl;
          } else if (isAffiliate) {
            const getParamsData = Object.entries(response)
                .filter((item) => item[0] !== "onlinePayment")
                .map(([key, value]) => `${key}=${value || 0}`)
                .reduce(
                    (previousValue, currentValue) =>
                        `${previousValue}&${currentValue}`
                )
            console.log("meals + onlinePayment + getParamsData", data.mealsPerDay, data.onlinePayment, getParamsData);
            window.location.href = isEn
                ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=433429`
                : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=433429`
          } else {
            window.location.href = isEn ? "/en/thank-you" : "/thank-you"
          }
          window.dataLayer.push({
            event: "ga.pageview",
            pageURL: isEn ? "/en/thank-you" : "/thank-you",
            pageType: "Purchase",
            testovani: testovani,
          })
        }, 300)
      } else {
        alert("Something went wrong, please try again!")
      }
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(OrderFormLayout)
