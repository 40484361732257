import cx from "classnames"
import { FastField } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./price.module.css"

export const days = {
  "2 týdny": {
    5: 10,
    6: 12,
  },
  Měsíc: {
    5: 20,
    6: 24,
  },
  "Dva měsíce": {
    5: 40,
    6: 48,
  },
  "1 week": {
    5: 5,
    6: 6,
  },
}

// function getDiscountPrice(price, plan) {
//   const discount =
//     plan && plan === "2 týdny" ? 5 : plan && plan === "2 měsíce" ? 8 : null
//     console.log("discount: ", price, plan, discount)
//   return (price - price * (discount / 100)).toFixed()
// }
//
// function getDiscountPricePerPortion(price, plan, program, week) {
//   const discountPortion = plan && plan === "2 týdny" ? 5 : null
//   return ((price - price * (discountPortion / 100)).toFixed() / 5).toFixed()
// }

function getDiscountPricePerPortion(price) {
  const discount = 0 // TODO: - Когда от 1 числа мы поменяем скидку на 20%, то при заказе для 1,2 людей скидка будет по-прежнему 20%, только в случае когда клиент захочет сделать заказ для троих и более людей, тогда скидка будет 30%.
  return (price - price * (discount / 100)).toFixed(2)
}

function getDiscountPrice(dayPrice, days) {
  return (dayPrice * days).toFixed(2)
}

// function getDiscountPricePerPortion(price) {
//   const discountPortion = 30
//   return ((price - price * (discountPortion / 100)).toFixed() / 5).toFixed()
// }

export const Price2 = ({
  price = 0,
  plan = null,
  program,
  week,
  delivery = 0,
  deliveryPrice: deliveryPriceValue = 0,
  totalPrice: totalPriceValue = 0,
  setFieldValue,
  handleSetDelivery,
}) => {
  const [discountPrice, setDiscountPrice] = useState(null)
  const [discountPortionPrice, setDiscountPortionPrice] = useState(null)
  const [deliveryPrice, setDeliveryPrice] = useState(deliveryPriceValue)
  const [totalPrice, setTotalPrice] = useState(
    window.localStorage.getItem("totalPrice")
  )

  const { t } = useTranslation()

  const getTotalPrice = (totalPrice, price) =>
    totalPrice
      ? Number(totalPrice).toFixed(2)
      : price
      ? (price * days[program][5]).toFixed(2)
      : 20 * days[program][5]

  // temporary unnecessary
  // useEffect(() => {
  //   const discountPriceValue = getDiscountPrice(price, plan)
  //   setDiscountPrice(discountPriceValue)
  //   const discountPortionPriceValue = getDiscountPricePerPortion(price, days[program][week])
  //   setDiscountPortionPrice(discountPortionPriceValue)
  // }, [price, plan])

  useEffect(() => {
    // console.log("--- deliveryPriceValue in Price", deliveryPriceValue);
    // setDeliveryPrice(deliveryPriceValue);
    if (deliveryPriceValue && deliveryPriceValue > 0) {
      // console.log("--- deliveryPriceValue > 0 && ", deliveryPriceValue);
      setDeliveryPrice(deliveryPriceValue)
      // console.log("--- setTotalPrice ", deliveryPriceValue, " + ", price);
      // setTotalPrice(deliveryPriceValue + price);
      // window.localStorage.setItem("totalPrice", deliveryPriceValue + price);
    } else {
      // console.log("--- deliveryPriceValue === null && price = ", price);
      setDeliveryPrice(null)
      // window.localStorage.setItem("totalPrice", price);
    }
  }, [deliveryPriceValue])

  useEffect(() => {
    // console.log("--- price in Price", price);
    // console.log("--- totalPriceValue in Price", totalPriceValue)
    const tp = Number(window.localStorage.getItem("totalPrice"))
    // console.log("--- price get totalPrice in Price", tp);
    if (tp !== totalPriceValue) {
      console.log("tp !== totalPriceValue", totalPriceValue)
      setTotalPrice(totalPriceValue)
      window.localStorage.setItem("totalPrice", getTotalPrice(totalPriceValue, price));
    } else {
      console.log("tp === totalPriceValue", tp, getTotalPrice(tp, price))
      setTotalPrice(tp)
      window.localStorage.setItem("totalPrice", getTotalPrice(tp, price))
    }
  }, [deliveryPrice, totalPriceValue])

  useEffect(() => {
    console.log("--- price in Price", price)
    console.log("--- totalPrice in Price", getTotalPrice(totalPrice, price))
    // const pr = totalPrice || price;
    // pr && setFieldValue("totalPrice", pr);
    window.localStorage.setItem("totalPrice", getTotalPrice(totalPrice, price))
  }, [totalPrice, price])

  return (
    <div className={styles.priceDiscountWrap}>
      {/* <h5 className={styles.priceDiscountWrapText}>{t("forms.discountTitle")} <span>10%</span></h5> */}
      <div className={styles.price}>
        <div className={styles.priceCol}>
          <h5 className={styles.priceTitle}>
            {t("forms.pricePerDayLabelPart1")}
          </h5>
          <p className={styles.priceText}>{t("forms.pricePerDayLabelPart2")}</p>
        </div>
        <div className={cx(styles.priceCol)}>
          {/* <div className={styles.priceDiscountValue}>
            {discountPortionPrice && (<><span id="pricePortionDiscount">
              {discountPortionPrice}
            </span> &euro;</>)}
          </div> */}
          {/* <div className={styles.priceDiscountValue}>
            <><span id="pricePortionDiscount">
              {totalPrice ? totalPrice : price ? price : "0"}
            </span> &euro;</>
          </div> */}
          <div className={styles.priceValue}>
            <span id="pricePortion">{price ? price : "0"}</span> &euro;
          </div>
          {deliveryPrice && (
            <div className={styles.delivery}>
              Doprava <b className={styles.deliveryPrice}>+50 &euro;</b> za den
            </div>
          )}
        </div>
      </div>
      <div className={styles.price}>
        <div className={styles.priceCol}>
          <h5 className={styles.priceTitle}>{t("forms.priceLabel")}</h5>
        </div>
        <div className={cx(styles.priceCol)}>
          {/* <div className={styles.priceDiscountValue}>
            {discountPrice && (<><span id="priceDiscount">{discountPrice}</span> &euro;</>)}
          </div> */}
          {/* <div className={styles.priceDiscountValue}>
            <>
              <span id="priceDiscount">
              {totalPrice ? Number((totalPrice * days[program][week])).toFixed(2) : price ? (price * days[program][week]).toFixed(2) : 420 * days[program][week]}
              </span> &euro;
            </>
          </div> */}
          <div className={styles.priceValue}>
            <span id="price">{getTotalPrice(totalPrice, price)}</span> &euro;
          </div>
          {deliveryPrice && (
            <div className={styles.delivery}>
              Doprava <b className={styles.deliveryPrice}>+50 &euro;</b> za den
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
