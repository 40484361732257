import { FastField, Field } from "formik";
import React, { forwardRef, useState } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import AutoComplete from "react-google-autocomplete";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next"
import cx from "classnames"; 
import orderFormStyles from "../order-form2.module.css";

import styles from "./input.module.css";

const DELIVERY_OUT_OF_ZONA_COSTS = 50;

export const GoogleSearchAddressInput = forwardRef(({setFieldValue, setPrice, handleSetDelivery}, ref) => {
    const [inputValue, setInputValue] = React.useState("");
    const [addressValue, setAddressValue] = React.useState("");
    const [queryAddressValue, setQueryAddressValue] = React.useState("");
    const [addressData, setAddressData] = React.useState({});
    const [prevPrice, setPrevPrice] = React.useState(window.localStorage.getItem("price"));
    const [prevTotalPrice, setPrevTotalPrice] = React.useState(window.localStorage.getItem("totalPrice"));
    const [error, setError] = useState(false);
    const [warning, setWarning] = useState(false);
    const [success, setSuccess] = useState(false);

    const { t } = useTranslation()

    async function checkAddress(address) {
        // console.log("address query value", address);
        let isAddDeliveryFee = false;
        let isDiscount = window.localStorage.getItem("isDiscount");
        let TYurl = null;

        const response = await fetch(`${process.env.GATSBY_APP_API_URL}/delivery/check-address`, {
            // const req = await fetch("https://test.nutritionpro.cz/api/delivery/check-address", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "q": address
            }),
        })
        if (response.status === 200) {
            const body = await response.json();
            isAddDeliveryFee = await body?.result?.isPointInPolygon
            TYurl = await body?.result?.address?.url
            // await console.log("isAddDeliveryFee", isAddDeliveryFee);
            // await console.log("isDiscount", isDiscount, !!isDiscount);
            await handleSetDelivery(isAddDeliveryFee);
            if (isAddDeliveryFee === true) {
                // await console.log("isAddDeliveryFee", prevPrice);
                await setWarning(false);
                await setError(false);
                await setSuccess(true);
                await window.localStorage.removeItem("deliveryPrice")
                await window.localStorage.setItem("totalPrice", Number(window.localStorage.getItem("totalPrice")));
                await setPrice(Number(window.localStorage.getItem("price")));
                await setFieldValue("totalPrice", Number(window.localStorage.getItem("totalPrice")))
                await setFieldValue("isAddressValid", true);
            } else if (isAddDeliveryFee === false) {
                // await console.log("!isAddDeliveryFee", prevPrice);
                await console.log("!isAddDeliveryFee", prevTotalPrice);
                await setWarning(true);
                await setError(false);
                await setSuccess(false);
                await window.localStorage.setItem("deliveryPrice", DELIVERY_OUT_OF_ZONA_COSTS)
                await setPrice(Number(window.localStorage.getItem("price") || prevPrice));
                await setFieldValue("totalPrice", prevTotalPrice + DELIVERY_OUT_OF_ZONA_COSTS)
                await window.localStorage.setItem("totalPrice", prevTotalPrice + DELIVERY_OUT_OF_ZONA_COSTS);
                await window.localStorage.setItem("price", Number(window.localStorage.getItem("price") || prevPrice))
                await setFieldValue("isAddressValid", true);
            } else if (isAddDeliveryFee === null) {
                await console.log("isAddDeliveryFee === null", prevTotalPrice);
                await window.localStorage.removeItem("deliveryPrice")
                await window.localStorage.setItem("totalPrice", prevTotalPrice);
                await setPrice(prevPrice || window.localStorage.getItem("price"));
                await setFieldValue("isAddressValid", false);
                await setSuccess(false);
                if (address === "") {
                    await setWarning(false);
                    await setError(false);
                } else {
                    await setWarning(false);
                    await setError(true);
                }
            }
        } else {
            await window.localStorage.removeItem("deliveryPrice")
            // await console.log("500 setPrice(prevPrice)", prevPrice);
            await window.localStorage.setItem("totalPrice", prevTotalPrice);
            await setPrice(prevPrice || window.localStorage.getItem("price"));
            await setFieldValue("isAddressValid", false);
            // await console.log("checkAddress address", address)
            await setSuccess(false);
            if (address === "") {
                await setWarning(false);
                await setError(false);
            } else {
                await setWarning(false);
                await setError(true);
            }
        }
        
        return {
            isAddDeliveryFee: isAddDeliveryFee,
            TYurl: TYurl,
        };
    }

    async function checkGoogleAddress(addressQuery) {
        // console.log("addressQuery query value", addressQuery);
        let address;
        const response = await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${addressQuery}&types=geocode&key=AIzaSyCDb4aYDvh7RgmZmE_gF1BZjayvlHNmZls`, {
            method: "GET",
            mode: 'no-cors',
        })
        if (response.status === 200) {
            const body = await response.json();
            address = await body?.predictions[0]?.description
            // await console.log("address", address);
            await checkAddress(address);
        } else {
            // await console.log("response not ok", response.ok);
            await checkAddress(addressQuery);
        }
    }

    const debouncedCheckAddress = React.useRef(
        debounce(async (addressQuery) => {
            setAddressData(await checkAddress(addressQuery));
        }, 500)
    ).current;

    async function handleChange(value) {
        // console.log("handleChange e", value);
        setFieldValue("promo", "");
        setAddressValue(value);
        setInputValue(value?.value?.description);
        if (value === "" || value?.value?.description === "") {
            setWarning(false);
            setError(false);
            setSuccess(false);
        }
        debouncedCheckAddress(value?.value?.description);
    }

    const isEn = document.location.pathname.includes("/en")

    React.useEffect(() => {
        return () => {
            debouncedCheckAddress.cancel();
        };
    }, [debouncedCheckAddress]);

    // React.useEffect(()  => {
    //     console.log("addressData", addressData);
    //     if (addressData.isAddDeliveryFee) {
    //         setFieldError("address", "warning")
    //     } else {
    //         setFieldError("address", "error")
    //     }
    // }, [addressData]);

    React.useEffect(() => {
        // console.log("addressValue", addressValue);
        setFieldValue("address", addressValue);
    }, [addressValue])

    // React.useEffect(() => {
    //     console.log("queryAddressValue", queryAddressValue);
    //     handleChange(queryAddressValue);
    // }, [queryAddressValue])

    React.useEffect(() => {
        // console.log("setPrevPrice", window.localStorage.getItem("price"))
        setPrevPrice(window.localStorage.getItem("price"));
        setPrevTotalPrice(window.localStorage.getItem("totalPrice"));
    })

    return (
        <>
            <GooglePlacesAutocomplete
                apiKey="AIzaSyCSyAn6Zke9iUXUgcLUhxz3FOPJ-fQCMss"
                selectProps={{
                    value: addressValue,
                    onChange: handleChange,
                    className: cx(styles.select, {
                        [styles.inputWarning]: warning,
                        [styles.inputError]: error,
                        [styles.inputSuccess]: success,
                    }),
                    styles: {
                        control: (provided, state) => ({
                          ...provided,
                          height: "100%",
                          borderRadius: "40px",
                          border: "none",
                          outline: "none",
                          paddingLeft: "12px",
                          whiteSpace: "nowrap",
                          background: "#F8F8F8",
                        }),
                        placeholder: defaultStyles => {
                          return {
                            ...defaultStyles,
                            color: "#d5dbe1",
                          }
                        },
                    }
                }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: "de"
                    }
                }}
                name="address"
            />
            {/* <AutoComplete
                ref={ref}
                name="address"
                autocomplete={"address"}
                ariaAutocomplete={"list"}
                apiKey={"AIzaSyCSyAn6Zke9iUXUgcLUhxz3FOPJ-fQCMss"}
                libraries={["places"]}
                options={{
                    componentRestrictions: { 
                        country: "cz"
                    },
                }}
                className={cx(orderFormStyles.input, {
                    [styles.inputWarning]: warning,
                    [styles.inputError]: error,
                    [styles.inputSuccess]: success,
                })}
                language={"cs"}
                value={inputValue}
                defaultValue={"Praha"}
                onChange={(e) => setInputValue(e.target.value)}
                onPlaceSelected={(place) => {
                    console.log("place", place.formatted_address);
                    setQueryAddressValue(place.formatted_address);
                }}
                onBlur={() => setQueryAddressValue(inputValue)}
            /> */}
            {/* <Field
                innerRef={ref}
                component="input"
                type="text"
                name="address"
                className={cx(orderFormStyles.input, {
                    [styles.inputWarning]: warning,
                    [styles.inputError]: error,
                    [styles.inputSuccess]: success,
                })}
                placeholder={t("forms.onlineOrderFormAddressLabel")}
                onChange={handleChange}
            /> */}
            {success && (
                <span className={cx(styles.inputHelper, styles.inputHelperSuccess)}>
                    {isEn ? "Free delivery" : "Kostenlose Lieferung"}
                </span>
            )}
            {warning && (
                <span className={cx(styles.inputHelper, styles.inputHelperWarning)}>
                    <svg width="17" height="17" style={{marginRight: "7px"}} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8.5" cy="8.5" r="8.5" fill="currentColor"/>
                        <path d="M7.89053 10.18L7.56653 7V4.6H9.42653V7L9.11453 10.18H7.89053ZM9.22253 12.856C9.02253 13.056 8.78253 13.156 8.50253 13.156C8.22253 13.156 7.98253 13.056 7.78253 12.856C7.58253 12.656 7.48253 12.416 7.48253 12.136C7.48253 11.856 7.58253 11.616 7.78253 11.416C7.98253 11.216 8.22253 11.116 8.50253 11.116C8.78253 11.116 9.02253 11.216 9.22253 11.416C9.42253 11.616 9.52253 11.856 9.52253 12.136C9.52253 12.416 9.42253 12.656 9.22253 12.856Z" fill="white"/>
                    </svg>
                    {isEn ? "Free delivery" : "Kostenlose Lieferung"}
                </span>
            )}
            {error && (
                <span className={cx(styles.inputHelper, styles.inputHelperError)}>
                    <svg width="17" height="17" style={{marginRight: "7px"}} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8.5" cy="8.5" r="8.5" fill="currentColor"/>
                        <path d="M7.89053 10.18L7.56653 7V4.6H9.42653V7L9.11453 10.18H7.89053ZM9.22253 12.856C9.02253 13.056 8.78253 13.156 8.50253 13.156C8.22253 13.156 7.98253 13.056 7.78253 12.856C7.58253 12.656 7.48253 12.416 7.48253 12.136C7.48253 11.856 7.58253 11.616 7.78253 11.416C7.98253 11.216 8.22253 11.116 8.50253 11.116C8.78253 11.116 9.02253 11.216 9.22253 11.416C9.42253 11.616 9.52253 11.856 9.52253 12.136C9.52253 12.416 9.42253 12.656 9.22253 12.856Z" fill="white"/>
                    </svg>
                    {isEn ? "Sorry! We are not in this locality yet" : "Es tut uns leid! Wir sind noch nicht in dieser Gegend"}
                </span>
            )}
        </>
    )
});
